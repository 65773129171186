import type {RouteLocationNormalized} from "vue-router";

export default defineAppConfig({
    layout: {
        showTitle: true,
        navigation: {
            enabled: true,
            logo: {
                component: 'Logo'
            },
            items: [
                // {
                //     name: 'Dashboard',
                //     icon: {name: 'lucide:badge-dollar-sign'},
                //     to: (route: RouteLocationNormalized) => {
                //         //todo this is workaround
                //         const currentWorkspace = useWorkspaceStore().workspace;
                //
                //         return `/workspaces/${currentWorkspace.workspaceID}`;
                //     }
                // },
                // {
                //     name: 'Withdrawal',
                //     to: (route: RouteLocationNormalized) => {
                //         const currentWorkspace = useWorkspaceStore().workspace;
                //
                //         return `/workspaces/${currentWorkspace.workspaceID}/withdrawal`;
                //     }
                // }
            ]
        },
        circularMenu: {
            enabled: true,
            tools: [
                {
                    component: 'ThemeToggle',
                },
                {
                    component: 'LanguageToggle'
                }
            ]
        },
        toolbar: {
            enabled: true,
            component: 'MerchantToolbar',
        },
    }
});